import React from "react"

export default function Header() {
  return (
      <div style={{height: "50px", backgroundColor: "black", marginBottom: "25px"}}>
      
      <div className="header">

        <div className="headerItems">
          <p className="headerItem"><a href="/">HOME</a></p>
          <p className="headerItem"><a href="/blog">BLOG</a></p>
          <p className="headerItem"><a href="https://store69687773.company.site/" target="_blank">SHOP</a></p>          
        </div>

      </div>  

      </div>    )
}
