import React from "react"
import Header from "../components/header/header"

import './reset.css'

export default function Home() {
  return (
    <div style={{textAlign: "center", margin: "0", padding: "0"}}>

      <Header />    

      <img src="/images/logo.png" style={{maxWidth: "300px", marginBottom: "25px", marginTop: "25px"}} />    
      
      <div style={{backgroundColor: "#aaa", padding: "50px"}}>
        <div style={{width: "90%", maxWidth: "900px", margin: "0 auto", textAlign: "left", fontFamily: "arial"}}>
          <h1>UNITE</h1>
          <div style={{textAlign: "left", letterSpacing: "1px", lineHeight: "1.5", marginBottom: "20px", color: "#444"}}>
            The Cancel Army is made of people just like you who believe in the power of uniting.  We know that when we work together we are stronger than when we work alone.  We seek to stop hate and injustice wherever possible by working within the system as well as any legal civil means available.
          </div>
          <div><i>United we can cancel hate and change the world.</i></div>
          <h1 style={{marginTop: "50px"}}>INFORM</h1>
          <div style={{textAlign: "left", letterSpacing: "1px", lineHeight: "1.5", marginBottom: "20px", color: "#444"}}>
            Our first goal is to inform our members when injustice is happening in our world.  We seek to provide information regarding positive means to affect change.  We believe that with this information, our members will make an impact in our society.
          </div>
          <div><i>Knowledge is power. </i></div>                    
          <h1 style={{marginTop: "50px"}}>RESIST</h1>
          
          <div style={{textAlign: "left", letterSpacing: "1px", lineHeight: "1.5", marginBottom: "20px", color: "#444"}}>
            We also acknowledge that sometimes working within the system doesn't provide the necessary results.  This is when The Cancel Army can display it's power.  By using peaceful, legal means we can create and sign petitions, protest and even boycott when necessary.  
          </div>
          <div><i>Resisting hate and injustice is our duty. </i></div>                    
        </div>
      </div>

      <div style={{backgroundColor: "#333", padding: "50px"}}>
        <div style={{width: "90%", maxWidth: "900px", margin: "0 auto", textAlign: "left", fontFamily: "arial", color: "white"}}>
          <h1>JOIN</h1>
          <div style={{textAlign: "left", letterSpacing: "1px", lineHeight: "1.5", marginBottom: "50px", color: "#aaa"}}>
            Joining us is easy.  All it takes is valid email address and a legitimate desire to change the world using peaceful means.  We will inform you of causes that fit our criteria and what you can do to help.  How you decide to help is up to you.  Join us today and help us make a difference for tomorrow.
          </div>

          <div style={{width: "300px", fontSize: "1.25em", fontWeight: "bold", backgroundColor: "#d1202a", borderRadius: "5px", color: "white", margin: "0 auto", padding: "15px", textAlign: "center", cursor: "pointer", marginBottom: "25px"}}>
            <a href="https://store69687773.company.site/" target="_blank" style={{textDecoration: "none", color: "white"}}>I Want To Join</a>
          </div>

          <div style={{width: "300px", fontSize: "1.25em", fontWeight: "bold", backgroundColor: "#d1202a", borderRadius: "5px", color: "white", margin: "0 auto", padding: "15px", textAlign: "center", cursor: "pointer"}}>
            <a href="https://store69687773.company.site/" target="_blank" style={{textDecoration: "none", color: "white"}}>Shop For Gear</a>
          </div>
          
        </div>
      </div>

      <div style={{backgroundColor: "#333", paddingTop: "50px"}}>
      <div></div>
      <img src="./images/bottom.png" style={{opacity: ".15"}} />      
      </div>


    </div>)
  
}
